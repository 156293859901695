import React from 'react';

import Locator from './Locator';
import { geolocated } from "react-geolocated";


const Comp = (props) => {
  const { stores, coords } = props;
  return (
    <Locator
      stores={stores}
      currentCoords={coords}
    />
  );
};

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(Comp);
