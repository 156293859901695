import styled from 'styled-components';
import {
  device
} from '../../utils';

export const Wrapper = styled.section`
  position: relative;
  height: auto;
  width: 100%;
  max-width: 100vw;
  display: flex;
  align-self: stretch;
  height: calc(100vh - var(--header-height));
  overflow: hidden;
  margin-bottom: 50px;

  .gm-style {
    font: inherit;
  }
`;

export const SearchBar = styled.div`
  flex: 0 0 100%;
  padding-top: 25px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    flex: 0 0 25%;
    min-width: 280px;
  }

  input {
    padding: 10px;
    font-size: 12px;
  }

  .stores-container {
    flex: 1;
  }

  .reset-zoom {
    padding: 10px 0;
    text-align: center;
    .button {
      margin: 0 auto;
    }

    @media ${device.laptopDown} {
      display: none;
    }
  }

  .header {
    padding: 0 20px 15px 20px;
    box-shadow: 10px 6px 8px 0 rgba(207, 207, 207, 0.5);

    h3 {
      font-size: 20px;
      margin-top: 0;
    }

    form {
      border: 0;
      padding: 0;
      margin: 0;
    
      label {
        font-size: 11px;
        font-weight: bold;
      }

      .search-input {
        display: flex;
        margin: 5px 0;

        input, button {
          outline: none;
        }
      }

      .filter-input {
        display: flex;
        margin-top: 8px;
        align-items: center;

        .label-text {
          margin-left: 5px;
          font-size: 12px;
          user-select: none;
          cursor: pointer;
          font-weight: bold;
          color: #e12519;
          line-height: 16px;
        }
      }

      input {
        margin: 0;
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #000;

        outline: none;
      }

      button {
        background: #000;

        svg {
          * {
            fill: #FFF!important;
          }
        }
      }
    }
  }
`;

export const StoreItem = styled.div`
  padding: 20px;
  padding-left: 10px;
  border-bottom: 1px solid #DFDFDF;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;

  .icon-col {
    flex: 0 0 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 15px;
    text-align: center;

    svg {
      * {
        fill: #e12519;
      }
    }

    strong {
      font-size: 10px;
      margin-top: 10px;
    }
  }

  .content-col {
    flex: 1 1 auto;
  }

  &:hover {
    &:after {
      content: '';
    }

    .icon-col {
      svg {
        * {
          fill: #000;
        }
      }
    }
  }

  * {
    pointer-events: none;
  }

  h3 {
    font-size: 15px;
    margin-top: 2px;
  }

  .infos {
    & > * {
      display: block;
      font-weight: bold;
      font-size: 11px;
      font-style: normal;
      margin: 3px 0;
    }

    .com {
      color: #e12519;
      margin: 5px 0;
    }
  }
`;

export const MapContainer = styled.div`
  flex: 1;
  height: 1px;
  visibility: hidden;

  @media ${device.tablet} {
    display: block;
    visibility: visible;
    height: auto;
  }
`;