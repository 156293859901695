import React from 'react';
import LocaleContext from './ctx';
import { formatMessage as innerFormatMessage } from './format';

const withLocale = (Component) => {
  return function LocaleComponent(props) {
    return (
      <LocaleContext.Consumer>
        {(value) => {
          const intl = {
            locale: value.locale,
            formatMessage: (key, params = {}) =>
              innerFormatMessage(value.translations, key, params),
          };
          return <Component {...props} intl={intl} />;
        }}
      </LocaleContext.Consumer>
    );
  };
};

export default withLocale;
