import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  display: block;
  transform: translate3d(-50%, -50%, 0);
  cursor: pointer;

  .dot {
    display: block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #e12519;
  }

  & > svg {
    * {
      fill: #E12519;
    }
  }

  &.active {
    z-index: 10000;

    .dot {
      background-color: #000;
    }

    & > svg {
      * {
        fill: #000;
      }
    }
  }

  &.show {
    z-index: 10005;
  }
`;
