import React from 'react';
import cx from 'classnames';

import { Icon } from '../../components/icons';

import Poi from '../../icons/poi-dark.svg';

import PoiCard from './PoiCard';

import {
  Wrapper,
} from './Poi.styles';

const MarkerPoi = (props) => {
  const { scale } = props;

  let content = null;
  let size;
  let dot = false;
  let active = props.show || props.$hover;
  let cardClass = [];

  if (active) {
    const width = props.$geoService.getWidth();
    const height = props.$geoService.getHeight();
    const geo = props.$getDimensions(props.$dimensionKey);

    const pos = {
      x: Math.round(geo.x * 100 / width),
      y: Math.round(geo.y * 100 / height)
    };

    if (pos.y > 50) {
      cardClass.push('ontop');
    } else {
      cardClass.push('onbottom');
    }

    if (pos.x > 50) {
      cardClass.push('onleft')
    } else {
      cardClass.push('onright');
    }
  }

  if (scale < 10) {
    size = `${props.scale * 1.5}px`;
    dot = true;

    content = (
      <div
        className="dot"
        style={{
          width: size,
          height: size,
        }}
      />
    );
  } else {
    size = `${props.scale * 2.5}px`;

    content = (
      <Icon
        src={Poi}
        width={size}
        height={size}
      />
    );
  }

  return (
    <Wrapper
      className={cx({
        active,
        show: props.show,
      })}
      style={{
        width: size,
        height: size,
      }}
    >
      {content}
      {!dot && active ? (
        <PoiCard
          key={props.$dimensionKey}
          className={cardClass.join(' ')}
          {...props.datas}
          preview={props.$hover && !props.show}
          onClose={props.onClose}
        />
      ) : null}
    </Wrapper>
  )
};

export default MarkerPoi;
