import styled from 'styled-components';

export const cardWidth = 230;

export const Wrapper = styled.div`
  position: absolute;
  box-shadow: 0 2px 15px 0 rgba(163, 163, 163, 0.5);
  min-width: ${cardWidth}px;
  padding: 20px;
  margin: 5px -15px;
  box-sizing: border-box;
  cursor: auto;

  &.ontop {
    bottom: 100%;
  }

  &.onbottom {
    top: 100%;
  }

  &.onleft {
    right: 100%;
  }

  &.onright {
    left: 100%;
  }

  background: #FFF;

  .title {
    font-size: 15px;
    padding: 7px 10px 5px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    margin-top: 0;
  }
  .close {
    position: absolute;
    top: 2px;
    right: 0px;
    cursor: pointer;
  }

  .infos {
    padding: 0px 10px;
  
    & > * {
      display: block;
      font-weight: bold;
      font-size: 11px;
      font-style: normal;
      margin: 5px 0;
      line-height: 1.3em;
    }

    a {
      cursor: pointer;
    }

    .com {
      color: #e12519;
      margin: 5px 0;
    }

    p {
      text-align: center;
    }
  }

  .line {
    display: flex;
    align-items: center;

    .icon-container {
      padding-right: 10px;
    }
  }
`;
