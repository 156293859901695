import React, { createRef } from 'react';
import GoogleMapReact from 'google-map-react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import defaultsDeep from 'lodash/defaultsDeep';
import Supercluster from 'supercluster';

import { isMobileOnly, isIOS } from 'react-device-detect';

import MarkerPoi from './Poi';
import ClusterPoi from './Cluster';

// import { Checkbox } from '../../components/form';
import { Icon } from '../../components/icons';
import Poi from '../../icons/poi-dark.svg';

import trads from './trads';

import { Wrapper, SearchBar, MapContainer, StoreItem } from './Locator.styles';
import withLocale from '../../services/i18n/withLocale';

const defaultPosition = {
  center: {
    lat: 46.227638,
    lng: 2.213749,
  },
  zoom: 6,
};

const preventDefault = (e) => e.preventDefault();

const transformGeoJson = (s) => ({
  type: 'Feature',
  properties: { cluster: false, storeId: s.id, store: s },
  geometry: {
    type: 'Point',
    coordinates: [parseFloat(s.lng), parseFloat(s.lat)],
  },
});

class Locator extends React.PureComponent {
  inputRef = createRef();
  state = {
    center: {
      lat: 46.227638,
      lng: 2.213749,
    },
    bounds: null,
    zoom: 6,
    displayStores: [],
    mapPoints: [],
    showMarker: null,
    filterRTF: false,
  };
  autocomplete;
  map;
  gmapApi;
  updateCenterWhenReady = false;
  showMap = true;
  cluster;
  stores;

  constructor(props) {
    super(props);

    this.showMap = !isMobileOnly;
    this.cluster = new Supercluster({
      radius: 80,
      maxZoom: 12,
    });
  }

  onPlacesChanged = (e) => {
    const place = this.autocomplete.getPlace();
    const { location } = place.geometry;

    this._setMapCenter(location.lat(), location.lng(), 14);
  };

  gmapReady = ({ map, maps }) => {
    this.map = map;
    this.gmapApi = maps;

    this.autocomplete = new maps.places.Autocomplete(
      this.inputRef && this.inputRef.current,
      {
        componentRestrictions: { country: 'fr' },
      }
    );
    this.autocomplete.addListener('place_changed', this.onPlacesChanged);

    const upCenter = this.updateCenterWhenReady;

    if (upCenter) {
      this._setMapCenter(upCenter.lat, upCenter.lng, 14);
    } else {
      this.setState(this.computeDisplayStores());
    }
  };

  componentWillUnmount = () => {
    this.gmapApi.event.clearInstanceListeners(this.autocomplete);
  };

  computeByDistance = (stores, state) => {
    const { center } = state;
    const { computeDistanceBetween } = this.gmapApi.geometry.spherical;
    const centerCoords = new this.gmapApi.LatLng(center.lat, center.lng);
    const distance = 30000;

    state.displayStores = stores;

    // Default country view return everything
    if (state.zoom < 10) {
      return state;
    }

    // Sort by distance
    state.displayStores = stores
      .filter((store) => {
        const coords = new this.gmapApi.LatLng(store.lat, store.lng);
        const storeDist = computeDistanceBetween(centerCoords, coords);
        store.distance = storeDist;
        return storeDist < distance;
      })
      .sort((a, b) => a.distance - b.distance);

    return state;
  };

  computeByBounds = (stores, state) => {
    const { zoom } = state;
    if (!this.map) {
      return state;
    }

    const bounds = this.map.getBounds();
    const bc = bounds.toJSON();

    state.displayStores = stores.filter((store) => {
      const coords = new this.gmapApi.LatLng(store.lat, store.lng);
      return bounds.contains(coords);
    });
    this.cluster.load(state.displayStores.map(transformGeoJson));
    state.mapPoints = this.cluster.getClusters(
      [bc.west, bc.south, bc.east, bc.north],
      zoom
    );

    return state;
  };

  computeDisplayStores = (newState = {}) => {
    const infos = defaultsDeep(newState, this.state);
    const { stores } = this.props;

    const { filterRTF } = infos;

    let newStores = stores.filter((s) =>
      filterRTF ? s.campaign === '1' : true
    );

    if (!this.showMap) {
      return this.computeByDistance(newStores, infos);
    }

    return this.computeByBounds(newStores, infos);
  };

  onBoundsChange = ({ center, zoom }) => {
    if (!this.showMap) {
      return;
    }

    this._computeCenter(center[0], center[1], zoom);
  };

  _computeCenter = (lat, lng, zoom) => {
    const newState = this.computeDisplayStores({
      center: {
        lat,
        lng,
      },
      zoom,
    });

    this.setState(newState);
  };

  onListClick = (param) => {
    const { displayStores } = this.state;
    const id = param.target.id;
    const store = displayStores.find((s) => s.id === id);

    if (!store) {
      return;
    }

    if (isMobileOnly && typeof window !== 'undefined') {
      const mapQuery = `?q=${store.address}`;
      const mapUrl = isIOS
        ? `http://maps.google.com/maps${mapQuery}`
        : `geo:${mapQuery}`;
      window.open(mapUrl, '_system');
      return;
    }

    this._setMapCenter(store.lat, store.lng, 14);

    this.setState({
      showMarker: id,
    });
  };

  componentDidUpdate = (prevProps) => {
    const { currentCoords } = this.props;
    if (prevProps.currentCoords === currentCoords) {
      return;
    }

    if (!this.map) {
      this.updateCenterWhenReady = {
        lat: currentCoords.latitude,
        lng: currentCoords.longitude,
      };
      return;
    }

    this._setMapCenter(currentCoords.latitude, currentCoords.longitude, 14);
  };

  _setMapCenter = (lat, lng, zoom) => {
    if (!this.showMap) {
      this._computeCenter(lat, lng, zoom);
      return;
    }

    this.map.setCenter(new this.gmapApi.LatLng(lat, lng));
    this.map.setZoom(zoom);
  };

  _handleCheckboxChange = (event) => {
    const filter = event.target.checked;

    const newState = this.computeDisplayStores({
      filterRTF: filter,
    });

    this.setState(newState);
  };

  _onChildClick = (key, childProps) => {
    const { showMarker, zoom } = this.state;
    const newMarker = showMarker === key ? null : key;

    let newZoom = 12;

    if (childProps.clusterId) {
      newZoom = this.cluster.getClusterExpansionZoom(childProps.clusterId) + 1;
    }

    if (newZoom <= zoom) {
      newZoom = zoom + 1;
    }

    if (zoom < 10 || childProps.clusterId) {
      this._setMapCenter(childProps.lat, childProps.lng, newZoom);
    }

    if (childProps.clusterId) {
      return;
    }

    this.setState({
      showMarker: newMarker,
    });
  };

  _resetChildClick = () => {
    this.setState({
      showMarker: null,
    });
  };

  renderStore = ({ index, style }) => {
    const { displayStores } = this.state;
    const store = displayStores[index];

    return (
      <StoreItem style={style} id={store.id} onClick={this.onListClick}>
        <div className="icon-col">
          <Icon src={Poi} width={11} />
          {store.distance ? (
            <strong>{Math.round(store.distance / 1000)}km</strong>
          ) : null}
        </div>
        <div className="content-col">
          <h3 className="bigtitle">{store.name}</h3>
          <div className="infos">
            <address>
              {store.street}
              <br />
              {store.postalCode} {store.town}
            </address>
            <a href={`tel:${store.phone}`}>{store.phone}</a>
            <a href={`mailto:${store.email}`}>{store.email}</a>
            {store.campaign === "1" ? (
              <span className="com">
                Participe à l’OP CFMOTO
              </span>
            ) : null}
          </div>
        </div>
      </StoreItem>
    );
  };

  renderPOIs = () => {
    const { zoom, mapPoints, showMarker } = this.state;

    if (!this.showMap) {
      return null;
    }

    return mapPoints.map((point) => {
      if (point.properties.cluster) {
        const { coordinates } = point.geometry;

        return (
          <ClusterPoi
            key={point.id}
            lat={coordinates[1]}
            lng={coordinates[0]}
            scale={zoom}
            count={point.properties.point_count}
            clusterId={point.id}
          />
        );
      }

      const { store } = point.properties;

      return (
        <MarkerPoi
          key={store.id}
          lat={store.lat}
          lng={store.lng}
          text={store.name}
          scale={zoom}
          datas={store}
          show={showMarker === store.id}
          onClose={this._resetChildClick}
        />
      );
    });
  };

  renderMap = () => (
    <MapContainer>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyBX4VKQILTwBxS58tSEICF8RGDyha4lOeE',
          libraries: ['places', 'geometry'],
        }}
        defaultCenter={defaultPosition.center}
        defaultZoom={defaultPosition.zoom}
        yesIWantToUseGoogleMapApiInternals
        onChange={this.onBoundsChange}
        onGoogleApiLoaded={this.gmapReady}
        onChildClick={this._onChildClick}
      >
        {this.renderPOIs()}
      </GoogleMapReact>
    </MapContainer>
  );

  render = () => {
    const { intl } = this.props;
    const { displayStores /*filterRTF*/ } = this.state;

    return (
      <Wrapper>
        <SearchBar>
          <div className="header">
            <h3 className="bigtitle">{intl.formatMessage(trads.title)}</h3>
            <form onSubmit={preventDefault}>
              <label htmlFor="locator-search">
                {intl.formatMessage(trads.searchAddress)}
              </label>
              <div className="search-input">
                {/* eslint-disable-next-line */}
                <input
                  id="locator-search"
                  ref={this.inputRef}
                  placeholder={intl.formatMessage(trads.searchPlaceholder)}
                />
              </div>
              {/* <div className="filter-input">
                <label>
                  <Checkbox
                    checked={filterRTF}
                    onChange={this._handleCheckboxChange}
                  />
                  <span className="label-text">Participe à l’opération Jackpot</span>
                </label>
              </div> */}
            </form>
          </div>
          <div className="stores-container">
            <AutoSizer>
              {({ height, width }) => (
                <List
                  height={height}
                  width={width}
                  itemCount={displayStores.length}
                  itemSize={150}
                >
                  {this.renderStore}
                </List>
              )}
            </AutoSizer>
          </div>
          <div className="reset-zoom">
            <button
              className="button"
              onClick={() =>
                this._setMapCenter(
                  defaultPosition.center.lat,
                  defaultPosition.center.lng,
                  defaultPosition.zoom
                )
              }
            >
              {intl.formatMessage(trads.viewAllStores)}
            </button>
          </div>
        </SearchBar>
        {this.renderMap()}
      </Wrapper>
    );
  };
}

export default withLocale(Locator);
