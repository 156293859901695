import React from 'react';
import cx from 'classnames';

import {
  Wrapper,
} from './Cluster.styles';

const MarkerPoi = (props) => {
  let content = null;
  let size;
  let active = props.$hover;

  size = `${Math.min(300 / props.scale, 35)}px`;

  content = (
    <div
      className="dot"
      style={{
        width: size,
        height: size,
      }}
    >
      {props.count}
    </div>
  );

  return (
    <Wrapper
      className={cx({
        active,
      })}
      style={{
        width: size,
        height: size,
      }}
    >
      {content}
    </Wrapper>
  )
};

export default MarkerPoi;
